/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Sticky from 'react-stickynode';

import { Navbar } from "@nextui-org/react";
import { SocialIcons } from './SocialIcons';
import { SearchInput } from './SearchInput';
import { ContactInfo } from './ContactInfo';
import { NavigationMenu } from './NavigationMenu';
import { BlogMegaMenu } from './BlogMegaMenu';
import { Logo } from './Logo';
import { ShoppingCart } from './ShoppingCart';
import { BiSearchAlt2 } from "@react-icons/all-files/bi/BiSearchAlt2";

export interface DoubleNavbarProps {
  handleSearchChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  navbarIsSticky: boolean | null;
  setNavbarIsSticky: (value: React.SetStateAction<boolean | null>) => void;
  toggleSearch: () => void;
  toggleRef: React.RefObject<HTMLButtonElement>;
  setMobileSidebarIsOpen: (value: React.SetStateAction<boolean>) => void;
  isMobile: boolean;
  isTablet: boolean;
}

export const DoubleNavbar = ({
  handleSearchChange,
  searchValue,
  navbarIsSticky,
  setNavbarIsSticky,
  toggleSearch,
  toggleRef,
  setMobileSidebarIsOpen,
  isMobile,
  isTablet,
}: DoubleNavbarProps)  => {
  const [blogMegaMenuIsOpen, setBlogMegaMenuIsOpen] = React.useState<boolean>(false);

  const handleStickyStateChange = (e: { status: any; }) => {
    if (e.status === Sticky.STATUS_FIXED) {
      setNavbarIsSticky(true);
    } else if (e.status === Sticky.STATUS_ORIGINAL || e.status === Sticky.STATUS_RELEASED) {
      setNavbarIsSticky(false);
    }
  }
  return (
    <>
      <Navbar
        variant="static"
        containerCss={{
          '@smMax': {
            display: 'none',
          },
        }}
        disableShadow={true}
      >
        <Navbar.Content>
          <SocialIcons />
        </Navbar.Content>
        <Navbar.Content
          css={{
            "@xsMax": {
              w: "100%",
              jc: "space-between",
            },
          }}
        >
          <Navbar.Item
            css={{
              "@xsMax": {
                w: "100%",
                jc: "center",
              },
            }}
          >
            <SearchInput
              handleSearchChange={handleSearchChange}
              searchValue={searchValue}
              bordered
              color="secondary"
              css={{
                opacity: 0.4,
                '&:hover': {
                  opacity: 1
                },
              }}
            />
          </Navbar.Item>
        </Navbar.Content>
        <Navbar.Content>
          <ContactInfo />
        </Navbar.Content>
      </Navbar>
      <Sticky
        className={`tt-header__sticky-navbar ${navbarIsSticky ? "tt-header__sticky-navbar--sticky" : ""} ${isMobile ? "tt-header__sticky-navbar--mobile" : isTablet ? "tt-header__sticky-navbar--tablet" : "tt-header__sticky-navbar--desktop"}`}
        top="#wpadminbar"
        innerZ={1000}
        onStateChange={handleStickyStateChange}
      >
        <Navbar
          variant="static"
          // shouldHideOnScroll={isMobile}
        >
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
          <Navbar.Content
            enableCursorHighlight
            activeColor="secondary"
            hideIn="sm"
            variant="underline"
          >
            <NavigationMenu setBlogMegaMenuIsOpen={setBlogMegaMenuIsOpen} blogMegaMenuIsOpen={blogMegaMenuIsOpen} />
          </Navbar.Content>
          <Navbar.Content>
            <BiSearchAlt2 onClick={toggleSearch} size="1.5em" className={`text-indigo-japones cursor-pointer ${navbarIsSticky || isMobile || isTablet ? "visible" : "invisible"} ${isMobile || isTablet ? "" : "opacity-60 hover:opacity-100 transition-opacity ease-linear"}`} />
            <ShoppingCart isMobile={isMobile} isTablet={isTablet} />
            <Navbar.Toggle
              aria-label="toggle navigation"
              onChange={(e) => setMobileSidebarIsOpen(e)}
              ref={toggleRef}
              showIn="sm"
            />
          </Navbar.Content>
        </Navbar>
        <BlogMegaMenu isOpen={blogMegaMenuIsOpen} isMobile={isMobile} />
      </Sticky>
    </>
  )
}