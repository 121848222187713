/* eslint-disable react/jsx-no-bind */
import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NextUIProvider } from "@nextui-org/react";

import { oxygenTtamayoContext } from '../../context';
import { theme } from '../../nextui-theme';
import { globalStyles } from '../../global-styles';

import { MobileSidebar } from './MobileSidebar';
import { SearchModal } from './SearchModal';
import { DoubleNavbar } from './DoubleNavbar';

const Header = () => {
  const [mobileSidebarIsOpen, setMobileSidebarIsOpen] = React.useState<boolean>(false);
  const [navbarIsSticky, setNavbarIsSticky] = React.useState<boolean | null>(null);
  const [searchIsOpen, setSearchIsOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const toggleRef = useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 651px) and (max-width: 960px)' });

  const toggleSearch = (): void => {
    setSearchIsOpen(!searchIsOpen);
  }

  globalStyles();

  const toggleSidebar = () => {
    toggleRef.current?.click();
  }

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(evt.target.value);
  }

  return (
    <NextUIProvider theme={theme} disableBaseline>
      <oxygenTtamayoContext.Provider value={window.oxygenTtamayo}>
        <DoubleNavbar
          handleSearchChange={handleSearchChange}
          navbarIsSticky={navbarIsSticky}
          setNavbarIsSticky={setNavbarIsSticky}
          searchValue={searchValue}
          setMobileSidebarIsOpen={setMobileSidebarIsOpen}
          toggleSearch={toggleSearch}
          toggleRef={toggleRef}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <MobileSidebar
          sidebarIsOpen={mobileSidebarIsOpen}
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <SearchModal
          handleSearchChange={handleSearchChange}
          setSearchIsOpen={setSearchIsOpen}
          searchIsOpen={searchIsOpen}
          searchValue={searchValue}
        />
      </oxygenTtamayoContext.Provider>
    </NextUIProvider>
  );
}

export default Header;
