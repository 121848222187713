/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useRef } from 'react';
import { loadedFont, PostType } from '../../interfaces';
// eslint-disable-next-line import/no-extraneous-dependencies
import { appStateContext } from '../../context';
import { RelatedPost } from './RelatedPost';
import { useIntersectionObserver } from 'usehooks-ts'
import loadFont from '../../helpers/font-loader';

interface RelatedPostsProps {
  posts: PostType[];
  loadFonts?: boolean;
}

export const RelatedPosts = ({ posts, loadFonts = true }: RelatedPostsProps) => {
  const { loadedFonts } = useContext(appStateContext);

  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  const requiredFonts: loadedFont[] = loadFonts ? [
    // {
    //   name: "DM Serif Display",
    //   url: "https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap",
    //   slug: "dm-serif-display"
    // },
    // {
    //   name: "Playfair Display",
    //   url: "https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap",
    //   slug: "playfair-display"
    // },
  ] : [];

  const fontLoadedOnce = (fontName: string) => {
    return loadedFonts.some(font => font.name === fontName);
  }

  const allFontsLoaded = () => {
    return requiredFonts.every(font => fontLoadedOnce(font.name));
  }

  useEffect(() => {
    if (loadFonts && !allFontsLoaded() && isVisible) {
      requiredFonts.forEach(font => {
        loadFont(font);
        loadedFonts.push(font);
      });
    }
  }, [isVisible, loadFonts]);

  return (
    <div ref={ref} className="tt-single__lower-container mb-8 md:mb-12 lg:mb-14 w-full flex flex-col items-center">
      <div className="tt-single__related-posts max-w-md md:max-w-4xl w-full px-4 md:px-6 lg:px-0">
        <div className="flex items-center mb-6">
          <div className="flex-grow h-px bg-gray-300" />
          <h3
            className="tt-single__related-posts__title flex-shrink text-center text-lg px-4 font-normal"
            style={{fontFamily: "EditorialNew, serif"}}
          >
            <span className='bg-white'>Artículos recomendados</span>
          </h3>
          <div className="flex-grow h-px bg-gray-300" />
        </div>
        <div className="tt-single__related-posts__container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
          {posts.map((post, i) => {
            return (
              <RelatedPost key={i} post={post} />
            )
          })}
        </div>
      </div>
    </div>
  )
}
