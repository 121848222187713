import React from 'react';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';

export const ContactInfo = () => {
  return (
    <div className="tt-header__contact-info-wrapper">
      <div className="group tt-header__contact-info-item opacity-40 hover:opacity-100 transition-opacity">
        <FaPhoneAlt className="mr-2 text-indigo-japones group-hover:text-armada-de-prusia" />
        <a className="tt-link-text tt-header__contact-info-text group-hover:text-armada-de-prusia" href="tel:5553731059" target="_self">(55) 5373-1059</a>
      </div>
      <div className="group tt-header__contact-info-item opacity-40 hover:opacity-100 transition-opacity">
        <FaEnvelope className="mr-2 text-indigo-japones group-hover:text-armada-de-prusia" />
        <a className="tt-link-text tt-header__contact-info-text group-hover:text-armada-de-prusia" href="mailto:contacto@ttamayo.com" target="_self">contacto@ttamayo.com</a>
      </div>
    </div>
  );
}
