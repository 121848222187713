import React, { useContext, useRef } from 'react';
import {
  useMasonry,
  usePositioner,
  useContainerPosition,
  useResizeObserver,
  useScroller,
  useInfiniteLoader
} from "masonic";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from "@react-hook/window-size";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga';

import { oxygenTtamayoContext } from '../../context';
import { SinglePostItem } from './SinglePostItem';
import { useInfinitePosts } from '../../hooks/useInfinitePosts';

const Single = () => {
  const { infinite_scroll_initial_elements, related_categories } = useContext(oxygenTtamayoContext);
  const containerRef = React.useRef(null);
  const [windowWidth, height] = useWindowSize();
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    height
  ]);
  const positioner = usePositioner({ width, columnWidth: 1, columnCount: 1, rowGutter: 0 });
  const { scrollTop, isScrolling } = useScroller(offset);
  const resizeObserver = useResizeObserver(positioner);
  const postsPerPage = 1;
  const { posts, noMorePosts, fetchMoreItems } = useInfinitePosts({
    query: {
      posts_per_page: postsPerPage,
      post__not_in: [infinite_scroll_initial_elements[0].id],
      post_status: 'publish',
      post_type: 'post',
      date_query: {
        before: infinite_scroll_initial_elements[0].post_date_gmt,
        // after december 2015
        after: {
          year: 2015,
          month: 12,
          day: 1,
        },
        inclusive: true,
      },
      cat: related_categories,
      // exclude posts without featured image or with a featured image that is the default one
      meta_query: [
        {
          key: '_thumbnail_id',
          compare: 'EXISTS',
        },
        {
          key: '_thumbnail_id',
          value: '1',
          compare: '>',
        },
      ],
    },
  });

  const maybeLoadMore = useRef(useInfiniteLoader(fetchMoreItems, {
    isItemLoaded: (index, items) => !!items[index],
    totalItems: noMorePosts ? posts.length : Infinity,
    minimumBatchSize: postsPerPage,
    threshold: 1,
  }));

  ReactGA.initialize('G-YLTH344WCF');

  return (
    <>
      {useMasonry({
        resizeObserver,
        positioner,
        scrollTop,
        isScrolling,
        height,
        containerRef,
        items: posts,
        overscanBy: 2,
        render: SinglePostItem,
        onRender: maybeLoadMore.current,
      })}
    </>
  );
};

export default Single;