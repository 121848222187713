import React, {useContext} from 'react';
import { oxygenTtamayoContext } from '../../context';
import LogoSVG from "../../../../img/logo-ttamayo-horizontal.svg";

export const Logo = () => {
  const {home_url} = useContext(oxygenTtamayoContext);
  return (
    <a className="tt-header__logo tt-link" href={home_url} target="_self">
      <LogoSVG />
    </a>
  );
}
