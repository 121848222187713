import React, { FC } from 'react';

export interface SplitTemplateProps {
  index: number;
  total: number;
  section: string;
  data?: any;
};

type SplitPostProps = {
  content: string;
  Template: FC<SplitTemplateProps>;
  splitInto?: number;
  splitEvery?: number;
  splitRegex: RegExp;
  joinString: string;
  data?: any;
};

export const SplitPost = ({ content, Template, splitInto, splitEvery, splitRegex, joinString, data }: SplitPostProps) => {
  const contentArray = content.split(splitRegex);
  let numSections: number;
  let sections: string[];
  if (splitInto) {
    numSections = splitInto;
    const itemsPerSection = Math.floor(contentArray.length / numSections);
    sections = Array(numSections).fill(null).map((_el, i) => {
      const startIndex = i * itemsPerSection;
      const endIndex = startIndex + itemsPerSection;
      return contentArray.slice(startIndex, endIndex).join(joinString);
    });
  } else if (splitEvery) {
    numSections = Math.ceil(contentArray.length / splitEvery);
    sections = Array(numSections).fill(null).map((_el, i) => {
      const startIndex = i * splitEvery;
      const endIndex = startIndex + splitEvery;
      return contentArray.slice(startIndex, endIndex).join(joinString);
    });
  } else {
    return <Template section={content} index={1} total={1} data={{
      ...data,
      noAds: true,
    }} />;
  }

  return (
    <>
      {sections.map((section, i) =>
        <Template section={section} index={i} key={"split-" + i} data={data} total={sections.length} />
      )}
    </>
  );
};