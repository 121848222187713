import React, {useContext} from 'react';
import { FaShoppingCart } from '@react-icons/all-files/fa/FaShoppingCart';
import { oxygenTtamayoContext } from '../../context';

export interface ShoppingCartProps {
  isMobile: boolean;
  isTablet: boolean;
}

export const ShoppingCart = ({
  isMobile,
  isTablet,
}: ShoppingCartProps) => {
  const {shopping_cart_url} = useContext(oxygenTtamayoContext);
  return (
    <a href={shopping_cart_url} target="_self">
      <FaShoppingCart size="1.5em" className={`text-indigo-japones hover:text-armada-de-prusia ${isMobile || isTablet ? "" : "opacity-60 hover:opacity-100 transition-opacity ease-linear"}`} />
    </a>
  );
};