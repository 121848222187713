import React, {useContext, FC} from 'react';
import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import {GrContact} from "@react-icons/all-files/gr/GrContact";
import {oxygenTtamayoContext} from '../../context';
import {flattenNavigationItemsFromLevel, removeObjectsByKeyValues, categoryUrlHasChildren, getPluralName, replaceKeysNested, nestObjInSelectedKey, alterObjProperty, findByProperty, nestedObjectToArray} from '../../utils';
import LogoSVG from "../../../../img/logo-ttamayo-vertical.svg";

const pluralNamesNavigationItems = {
  "Blog": "Ver todos los artículos",
  "Cursos y clases": "Ver todos los cursos y clases",
  "Quiénes somos": "Acerca de nosotros",
  "Productos": "Ver todos los productos",
}

const menuItemsKeys = {nameKey: "name", childrenKey: "children", urlKey: "url"};

type MobileSidebarProps = {
  sidebarIsOpen: boolean,
  toggleSidebar: () => void,
  isMobile: boolean,
  isTablet: boolean,
}

export const MobileSidebar: FC<MobileSidebarProps> = (props) => {
  const {main_menu, category_list} = useContext(oxygenTtamayoContext);
  const mainMenu = nestedObjectToArray(main_menu, "children");
  // const blogCategories = nestedObjectToArray(category_list, "child_categories");

  const renderNavigationItems = (items: any[], {nameKey, childrenKey, urlKey}: { nameKey: string; childrenKey: string; urlKey: string; }) => {
    return items.map(i => {
      const childLength = i[childrenKey].length;
      return {
        title: i[nameKey],
        // We'll use the % symbol to indicate that the category has children
        itemId: childLength ? "%" + i[urlKey] : i[urlKey],
        subNav: childLength ? [{
          title: getPluralName(i[nameKey], "Ver todos", pluralNamesNavigationItems),
          itemId: i[urlKey]
        }].concat(renderNavigationItems(i[childrenKey], {nameKey, childrenKey, urlKey})) : []
      }
    });
  }

  const menuItemsUnfiltered =
    flattenNavigationItemsFromLevel(
      nestedObjectToArray(nestObjInSelectedKey(
        replaceKeysNested(main_menu, {
          title: "name",
        }),
        {
          keyMatch: "name",
          valueMatch: "Blog"
        }, {
          keyToInsert: "children",
          objToInsert: nestedObjectToArray(replaceKeysNested(category_list, {
            child_categories: "children",
            category_url: "url"
          }), "child_categories")
        }
      ), "children"), 1, menuItemsKeys
    );

  const menuItemsUnNestedReplacement = removeObjectsByKeyValues(menuItemsUnfiltered, "children", [{"name": "Sin categoría"}]);

  const menuItems = alterObjProperty(menuItemsUnNestedReplacement, "children", {
    valueCondition: (item: any) => item.depth > 1
  }, {
    keyToAlter: "name",
    // prepend with a dash for each level of depth
    alterFunction: (item: any) => "— ".repeat(item.depth - 1) + item.name
  });

  const handleNavigationSelect = (e: any) => {
    if (
      !/^%/.test(e.itemId)
      && !categoryUrlHasChildren(flattenNavigationItemsFromLevel(menuItems, 0, menuItemsKeys), e.itemId, menuItemsKeys)
    ) {
      window.location.href = e.itemId;
    }
  }

  const getUbicacionYConctacto = findByProperty(mainMenu, i => i.title === "Ubicación y contacto");

  return (
    <>
      <div
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => props.toggleSidebar()}
        // eslint-disable-next-line react/jsx-no-bind
        onTouchStart={() => props.toggleSidebar()}
        className={`fixed inset-0 z-8000 block transition-opacity bg-black opacity-50 ${
          props.sidebarIsOpen ? "block" : "hidden"} ${!props.isMobile && !props.isTablet ? "hidden" : ""}`}
      />
      <div
        className={`fixed inset-y-0 left-0 z-9000 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 ${
          props.sidebarIsOpen ? "ease-out translate-x-0" : "ease-in -translate-x-full"} ${!props.isMobile && !props.isTablet ? "hidden" : ""}`}
      >
        <div className="flex items-center justify-center mt-2 text-center py-6">
          <span>
            <a target="_self" href="/">
              <LogoSVG className=" w-20" />
            </a>
          </span>
        </div>

        <Navigation
          activeItemId={window.location.href}
          // eslint-disable-next-line react/jsx-no-bind
          onSelect={handleNavigationSelect}
          items={renderNavigationItems(menuItems, menuItemsKeys)}
        />

        <div className="bottom-0 w-full mt-8 pb-8 bg-white">
          <Navigation
            activeItemId={window.location.href}
            items={[
              {
                // title: getUbicacionYConctacto.title,
                title: "Contáctanos",
                itemId: getUbicacionYConctacto.url,
                elemBefore: () => <GrContact />
              }
            ]}
            // eslint-disable-next-line react/jsx-no-bind
            onSelect={handleNavigationSelect}
          />
        </div>
      </div>
    </>
  );
}
