import React, { FC } from 'react';
import { SplitPostData } from '../../interfaces';
import { SplitTemplateProps } from './SplitPost';
import { AdSpace } from './AdSpace';
import { LowerMeta } from './LowerMeta';

export const SplitPostTemplate: FC<SplitTemplateProps> = ({ index, section, data, total }: {
  index: number;
  section: string;
  data?: SplitPostData;
  total: number;
}) => {
  const adSpaces = data?.shortcode_locations || [];
  const noAds = data?.noAds;
  const inContentAd = adSpaces.find((adSpace: any) => adSpace.in_content && adSpace.id === 'in_content_horizontal');
  const stickySidebarAd = adSpaces.find((adSpace: any) => adSpace.id === 'sticky_sidebar');
  const isLast = index === total - 1;
  return (
    <>
      {(!noAds && inContentAd && index > 0) && <div className="px-4 mx-auto flex justify-center content-center mt-8 mb-12 relative z-20">
        <AdSpace
          conditionalVariables={data}
          conditionalContent={inContentAd.shortcodes}
          id={inContentAd.id}
          index={index}
          total={total}
        />
      </div>}
      <main className="justify-between items-stretch px-4 lg:px-0 mx-auto lg:flex mt-8 mb-12">
        <section className={
          [
            "flex-grow",
            "tt-single__content-wrapper",
            !noAds && stickySidebarAd && "2xl:pr-4",
          ].filter(Boolean).join(' ')
        }>
          <div className="tt-single__content" dangerouslySetInnerHTML={{ __html: section }} />
          <div className='tt-single__content-lower-meta'>
            {isLast && <LowerMeta data={data} />}
          </div>
        </section>
        {!noAds && stickySidebarAd &&
          <aside style={{width: '300px'}} className="hidden flex-none 2xl:block">
            <div className="sticky top-40 ">
              <AdSpace
                conditionalVariables={data}
                conditionalContent={stickySidebarAd.shortcodes}
                id={stickySidebarAd.id}
                index={index}
                total={total}
              />
            </div>
          </aside>
        }
      </main>
    </>
  );
};