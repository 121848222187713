import "./sass/main.scss";


import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';

import Header from './ts/components/Header/Header';
const Single = lazy(() => import('./ts/components/Single/Single'));
const Archive = lazy(() => import('./ts/components/Archive/Archive'));

const bodyHasClass = (className: string) => document.body?.classList.contains(className);

// ng-controller is added by Oxygen, we don't want to render inside the Oxygen editor
if (document.body?.attributes && !document.body?.attributes["ng-controller"]) {
  if (document.querySelector('.tt-header') !== null) {
    const headerContainer = document.getElementById("tt-header") as HTMLElement;
    const headerRoot = createRoot(headerContainer);
    headerRoot.render(<Header/>);
  }

  if (bodyHasClass?.("single-post")) {
    const singleContainer = document.querySelector(".tt-single") as HTMLElement;
    const singleRoot = createRoot(singleContainer);
    singleRoot.render(<Single/>);
  }

  if (bodyHasClass?.("archive") || bodyHasClass?.("search") || bodyHasClass?.("blog")) {
    const archiveContainer = document.querySelector(".tt-archive") as HTMLElement;
    const archiveRoot = createRoot(archiveContainer);
    archiveRoot.render(<Archive/>);
  }
}
