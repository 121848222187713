import { parseSrcset } from 'srcset';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from "@react-hook/window-size";

export const srcsetSizes = ({
  defaultImgWidth,
  imgWidth,
  srcSet,
}: {
  defaultImgWidth: number,
  imgWidth: number,
  srcSet: string,
}) => {
  const [windowWidth, height] = useWindowSize();
  const srcsetArray = parseSrcset(srcSet);
  const containerProportion = imgWidth / windowWidth;

  const srcset_sizes_arr = srcsetArray.map((image, i) => {
    const proportion = Math.round(image.width ? image.width / containerProportion : imgWidth);
    // eslint-disable-next-line unicorn/prefer-ternary
    if (i < srcsetArray.length) {
      return {
        media: `(max-width: ${proportion}px)`,
        size: image.width,
      };
    } else {
      return;
    }
  });

  // Sort the media queries by ascending order of media width
  srcset_sizes_arr.sort((a, b) => (a && b && a.size && b.size) ? a.size - b.size : 0);

  // Add the default size to the end of the array
  srcset_sizes_arr.push({
    media: "",
    size: defaultImgWidth,
  });

  // Create the srcset_sizes string by combining the media and size values of each object in the array
  const srcset_sizes = srcset_sizes_arr.map((item) => item && `${item.media} ${item.size}px`).join(", ");

  return srcset_sizes;
}