import React, { FC, useContext } from 'react';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { oxygenTtamayoContext } from '../../context';
import { objToArr } from '../../utils';
import { Link, Button, Text, Container } from '@nextui-org/react';

const renderBlogMegaMenu = (items: any[], {name, children, url}: { name: string; children: string; url: string; }, level = 0) => {
  const {blog_url} = useContext(oxygenTtamayoContext);
  return (
    <>
      {level === 0 &&
        <Text
          h2
          css={{
            textGradient: "45deg, rgb(50 50 200) -20%, rgb(0 100 240) 50%",
            textTransform: "uppercase",
            letterSpacing: "0 !important",
            fontWeight: "300 !important",
          }}
        >Categorías</Text>
      }
      <ul className={`columns-3xs ${level > 0 ? "text-xs" : "text-base m-0"}`}>
        {items.map (i => (
          <li key={i[url]}>
            <Link
              block
              href={i[url]}
              color={i[url] === window.location.href ? "secondary" : "secondary"}
              css={{
                fontWeight: i[url] === window.location.href ? "bold" : "normal",
                fontSize: level > 0 ? "0.85em" : "1em",
                // textTransform: level > 0 ? "none" : "uppercase",
                // fontFamily: level > 0 ? "inherit" : "",
                // letterSpacing: level > 0 ? "inherit" : "0.05em",
                '&::before': {
                  content: level > 0 ? "'-'" : "''",
                  marginRight: level > 0 ? "0.5em" : "0",
                },
                '&:hover': {
                  color: "var(--nextui-colors-primary)",
                  boxShadow: "0px 0px 0px 2px var(--nextui-colors-primary) inset",
                },
                color: i[url] === window.location.href ? "var(--nextui-colors-secondary)" : "var(--nextui-colors-secondary)",
              }}
            >{i[name]}</Link>
            {i[children].length ? renderBlogMegaMenu(objToArr(i[children]), {name, children, url}, level + 1) : false }
          </li>
        ))}
        {level === 0 &&
          <Button
            key={blog_url}
            as={Link}
            color="gradient"
            ghost
            auto
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => window.location.href = blog_url}
          >
            Ver todos los artículos
          </Button>
        }
      </ul>
    </>
  )
}

type BlogMegaMenuInnerProps = {
  className?: string
}

const BlogMegaMenuInner: FC<BlogMegaMenuInnerProps> = (props) => {
  const {category_list} = useContext(oxygenTtamayoContext);
  const blogCategories = objToArr(category_list);
  const blogCategoriesWithoutUncategorized =
    blogCategories.filter((i: any) => (i.name !== "Uncategorized" && i.name !== "Sin categoría"));

  return (
    <div className={props.className}>
      {renderBlogMegaMenu(blogCategoriesWithoutUncategorized, {name: "name", children: "child_categories", url: "category_url"})}
    </div>
  )
}

type BlogMegaMenuProps = {
  isOpen: boolean,
  isMobile: boolean,
}

export const BlogMegaMenu: FC<BlogMegaMenuProps> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div
      className={`absolute w-full ${(props.isOpen && !props.isMobile) ? "md:block" : "hidden"} py-9 drop-shadow-2xl`}
      style={{
        background: "linear-gradient(to bottom, var(--nextui-colors-blue200) 0, var(--nextui-colors-backgroundAlpha) 70px, var(--nextui-colors-backgroundAlpha) 100%)",
        backdropFilter: "blur(8px)",
      }}
    >
      <Container lg>
        <BlogMegaMenuInner />
      </Container>
    </div>
  );
}

export default BlogMegaMenu;
